<template>
  <div>
    <portal to="page-top-title">New Badword Category</portal>
    <portal to="page-top-right"></portal>

    <box-form action="create" data-prop="formData" url="/badwords/categories" redirect="badwords-categories-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import Form from '@/modules/utils/Form'

export default {
  name: 'BadwordCategoriesNew',
  metaInfo () {
    return { title: 'Badwords | Categories | New' }
  },
  components: {
    BoxForm,
    FormGroupCheck,
    FormGroupInput
  },
  data () {
    return {
      formData: {
        name: Form.types.input(),
        active: Form.types.boolean(true)
      }
    }
  }
}
</script>
